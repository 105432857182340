<template>
  <b-card-code
    no-body
    title="Processos Pendentes"
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entradas</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            size="sm"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'editar-processo'}"
          >
            Adicionar Processo
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
            />
            <v-select
              v-model="filtroEstado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="opcoesEstado"
              class="invoice-filter-select"
              placeholder="Selecionar Estado"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      :items="filteredItems"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
      stacked="md"

      :filter="filtroEstado"
    >
      <template #cell(dataHoraAbertura)="data">

        {{ ('0' + new Date(data.value).getDate()).slice(-2) + '/' + ('0' + (new Date(data.value).getMonth() + 1)).slice(-2)
          + '/' + new Date(data.value).getFullYear() }}

      </template>
      <template #cell(descricaoEstado)="data">
        <b-badge :variant="status[1][data.value]">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(opcoes)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'editar-processo-id', params: { id: data.item.id } })"
          />
          <b-tooltip
            title="Editar Processo"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'detalhe-processo', params: { id: data.item.id } })"
          />
          <b-tooltip
            title="Visualizar Processo"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"> Mostrar {{ (currentPage - 1) * perPage + 1 }} até {{ Math.min(currentPage * perPage, totalRows) }} de {{ totalRows }} registos .</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card-code>
</template>
<script>
import {
  BTable, BPagination, BBadge, BTooltip, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BPagination,
    BBadge,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCardCode,
    vSelect,
  },
  data() {
    return {
      items: [], // Dados da tabela
      fields: [
        {
          key: 'processo', label: 'Processo',
        },
        {
          key: 'dataHoraAbertura', label: 'Data',
        },
        { key: 'codigoCliente', label: 'Cliente', sortable: true },
        { key: 'nomeCliente', label: 'Nome', sortable: true },
        { key: 'referenciaContrato', label: 'Contrato', sortable: true },
        { key: 'descricaoProb', label: 'Problema', sortable: true },
        { key: 'descricaoEstado', label: 'Estado', sortable: true },
        { key: 'opcoes', label:'...' },
      ],
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 50],
      searchQuery: null,
      opcoesEstado: [
        'PENDENTE',
        'REVISTO',
        'FATURADO',
        'TERMINADO',
        'INICIADO',
      ],
      filtroEstado: null,
      status: [{
        PENDENTE: 'PENDENTE', REVISTO: 'REVISTO', FATURADO: 'FATURADO', TERMINADO: 'TERMINADO', INICIADO: 'INICIADO', CANCELADO: 'CANCELADO',
      },
      {
        PENDENTE: 'light-primary', INICIADO: 'light-success', CANCELADO: 'light-danger', FATURADO: 'light-warning', REVISTO: 'light-info', TERMINADO: 'light-dark',
      }],
      dataMeta: {},
    }
  },
  computed: {

    totalRows() {
      return this.items.length
    },
    pagedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.items.slice(start, end)
    },

    filteredItems() {
      // Filtrar com base no estado selecionado
      const stateFiltered = this.items.filter(item => this.filtroEstado === null || this.filtroEstado === item.descricaoEstado)

      // Filtrar com base na busca
      return stateFiltered.filter(item => {
        if (!this.searchQuery) {
          return true // Mostrar todos se a busca estiver vazia
        }
        const query = this.searchQuery.toLowerCase()
        return (
          item.processo.toLowerCase().includes(query)
          || item.codigoCliente.toLowerCase().includes(query)
          || item.nomeCliente.toLowerCase().includes(query)
          || item.referenciaContrato.toLowerCase().includes(query)
          || item.descricaoProb.toLowerCase().includes(query)
        )
      })
    },
  },
  created() {
    this.getProcessos()
  },
  methods: {

    getProcessos() {
      axios.get('/api/v1/processos/pendentes/complete')
        .then(res => {
          this.items = res.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
